import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngNFTLaunchpadPage = loadable(() => import("../components/NFTLaunchpadPage/english.js"));
const ArabicNFTLaunchpadPage = loadable(() => import("../components/NFTLaunchpadPage/arabic.js"));
const ChineseNFTLaunchpadPage = loadable(() => import("../components/NFTLaunchpadPage/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-4.webp",
            "name": "A Leading NFT Launchpad Development Company ",
            "description": "Softtik has the legacy of offering top-tier development services as a top NFT launchpad development company. Reach out for market-ready solutions.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "reviewCount": "148"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "How do I join the NFT Launchpad?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p><b>Many launchpads are available in the blockchain sector. Pay the set charges and follow the rules to join any launchpad. For example, you can join Binance Launchpad by paying set BNB at any time.</p>"
                }
            }, {
                "@type": "Question",
                "name": "What's the difference between Launchpad and Launchpool?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Launchpad is the platform for listing projects, whereas launch-pool is a platform to earn new tokens by depositing a specific amount of tokens. In launch-pool, users get new tokens based on their staked tokens. <b>Launchpad is used to raise funds for new projects</b>.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Are NFT Launchpad profitable?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Of course, the <b>NFT launchpad</b> is a lucrative and emerging platform that can be an excellent source to raise funds. The artists, musicians, and other independent entities developing projects can reach a greater audience to raise more funds.</p>"
                }
            }
            ]
        }
    ]
}

export class NFTLaunchpadPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;
        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/nft-launchpad-development-services/"
                        title="NFT Launchpad Development Company - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-4.webp"
                        description="Softtik has the legacy of offering top-tier development services as a top NFT launchpad development company. Reach out for market-ready solutions."
                    />
                    <main onWheel={this.onScroll}>
                        <div id="wrapper">
                            <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicNFTLaunchpadPage />
                                    : lang == "China"
                                        ? <ChineseNFTLaunchpadPage />
                                        : <EngNFTLaunchpadPage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default NFTLaunchpadPage;